import { Link } from "react-router-dom";
import "./style.css";

function Page() {
  return (
    <div className="vanligsida">
      <h1>Om Rörlighet i staten</h1>
      <iframe
        className="streamio-player"
        src="https://streamio.com/api/v1/videos/66d047c96f8d8d24dc000005/public_show?player_id=59eed3d56f8d8d20b5000001"
        title="Vad är RIS?"
        allow="autoplay; encrypted-media"
        allowFullScreen
      ></iframe>
      <span className="ingress">
        Rörlighet i staten är ett samarbete över myndighetsgränser. Vi delar
        idéer, erfarenheter och kunskap som stärker medarbetare och utvecklar
        verksamheten. Tillsammans vill vi göra staten till Sveriges bästa
        arbetsplats.
      </span>
      <br />
      <br />
      <h3>Genom Rörlighet i staten kan våra medarbetare:</h3>
      <ul>
        <li>
          Dela erfarenheter i nätverk – med kollegor från andra myndigheter
        </li>
        <li>
          Prova på att jobba på en annan myndighet – via in-/utlån eller praktik
        </li>
        <li>
          Utvecklas i sin yrkesroll – genom medverkan i skräddarsydda program
          och kurser
        </li>
      </ul>
      <br />
      <p>
        <a
          rel="noopener"
          data-id="3620"
          href="/media/1469/RIS Verksamhetsberättelse 2023.pdf"
          target="_blank"
          title="RIS Verksamhetsberättelse 2023"
        >
          Verksamhetsberättelse 2023
        </a>
      </p>
      <p>
        <span>
          <Link to="/deltagare-i-aktiviteter-beraettar/">
            Intervjuer med personer som har deltagit
          </Link>{" "}
          i olika aktiviteter.
          <br />
        </span>
      </p>
      <br />
      <h3>2024 satsar vi på följande:</h3>
      <ul>
        <li>
          <Link to="/program-foer-din-utveckling/leda-utan-att-vara-chef">
            Leda utan att vara chef
          </Link>
        </li>
        <li>
          <Link to="/program-foer-din-utveckling/ledarskapsutveckling-foer-chefer">
            Ledarskapsutveckling för chefer
          </Link>
        </li>
        <li>
          <Link to="/program-foer-din-utveckling/introduktionsutbildning">
            Introduktionsutbildning: Att arbeta som statligt anställd
          </Link>
        </li>
        <li>
          <Link to="/dela-erfarenhet-i-naetverk/paagaaende-naetverk">
            Nätverk
          </Link>
        </li>
        <li>
          <Link to="/jobba-paa-annan-myndighet">Personallån/praktik</Link>
        </li>
        <li>
          <Link to="/inspirationsforelasningar-och-samtal">
            Digitala föreläsningar
          </Link>
        </li>
      </ul>
      <p>&nbsp;</p>
      <h2>Vi välkomnar fler myndigheter!</h2>
      <p>
        Idag är 20 myndigheter medlemmar i Rörlighet i staten. Vi blir gärna
        fler!
      </p>
      <p>
        Samarbetet i Rörlighet i staten är ett effektivt sätt att utveckla såväl
        medarbetare som verksamhet – samtidigt som vi stärker staten som
        attraktiv arbetsgivare. Vi tror på kraften i att dela idéer,
        erfarenheter och kunskap och vill kontinuerligt utveckla vårt samarbete,
        så att det anpassas efter våra behov och fokuserar på det som ger störst
        nytta för våra verksamheter.
      </p>
      <p>
        Vi ser stora fördelar med att skräddarsy gemensamma utvecklingsprogram.
        Det är resurseffektivt och skapar ytterligare nätverk. Genom att vi alla
        har den statliga värdegrunden som ramverk stärker vi en god
        förvaltningskultur.&nbsp;De flesta aktiviteter deltar alla myndigheter
        i, men vi kan också samverka i mindre konstellationer. Några myndigheter
        inom Rörlighet i staten har till exempel en gemensam
        introduktionsutbildning för nyanställda. Du hittar en lista på de
        myndigheter som deltar i samarbetet på sidan&nbsp;
        <Link to="/deltagande-myndigheter">Deltagande myndigheter</Link>
      </p>
      <p>
        Vill du veta mer? Kontakta vår samordnare Christina Frimodig,
        christina.frimodig@naturvardsverket.se <span>eller 070-673 47 47.</span>
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
